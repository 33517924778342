import Countdown from 'react-countdown';
import {dateToEpoch} from "../../helpers/date";

const Completionist = () => <div className='timers'><span className='end-event'>End Event!</span></div>;

const renderer = ({ days,hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return (
            <div className='timers'>
                <span className='card-time'>
                    <b data-text={days}>{days}</b>
                    <i>Days</i>
                </span>
                <br/>
                <span className='card-time'>
                    <b data-text={hours}>{hours}</b>
                    <i>Hours</i>
                </span>
                <br/>
                <span className='card-time'>
                    <b data-text={minutes}>{minutes}</b>
                    <i>Minutes</i>
                </span>
                <br/>
                <span className='card-time'>
                    <b data-text={seconds}>{seconds}</b>
                    <i>Second</i>
                </span>
            </div>
        )
    }
};

const CountdownThemeOne = (props) => {
    // console.log('COUNTDOWN: ', props);
    return (
        <div className='countdown countdown-theme-one'>
            <Countdown
                date={dateToEpoch(props.launch_datetime) * 1000}
                renderer={renderer}
            />
        </div>
    )
}

export default CountdownThemeOne;
