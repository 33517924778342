import React, { useEffect, useState } from "react"
import { Configs } from "../../configs/configs"
import { requestGetElements } from "../../queries/elements"
import { Schema } from "../../schema"

const Home = (props) => {
  const [slides, setSlides] = useState([])
  const [current, setCurrent] = useState(0)
  const [loading, setLoading] = useState(true)

  const [isFullScreen, setIsFullScreen] = useState(false)

  const NextSlide = (cn) => {
    const NextIndex = slides.length === cn + 1 ? 0 : cn + 1
    setCurrent(NextIndex)
    setTimeout(
      NextSlide.bind(this, NextIndex),
      slides[NextIndex]["display_duration"] * 1000
    )
  }

  const getData = () => {
    const date = new Date()

    const firstDayOfCurrentMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    ).toISOString()

    const lastDayOfCurrentMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).toISOString()

    requestGetElements({
      from: firstDayOfCurrentMonth,
      to: lastDayOfCurrentMonth,
    }).then((response) => {
      console.log("Data Fetch 🔄")
      setLoading(false)
      setCurrent(0)
      const data = response.data.data.filter((s) => {
        if (s.element_type === "ElementType.theme") {
          s.element_type = "theme"
        } else if (s.element_type === "ElementType.logo") {
          s.element_type = "logo"
        } else {
          s.element_type = "countdown"
        }
        return s
      })
      const fetchedSlides = data.filter((item) => !!item.file_url)

      const countdowns = fetchedSlides.filter(
        (item) => item.element_type === "countdown" && item.meta.slides
      )
      const slidesWihtoutMeta = fetchedSlides.filter(
        (item) => item.element_type !== "countdown"
      )
      countdowns.forEach((item) => {
        const slide = item.meta.slides.map((slide) => ({
          ...slide,
          launch_datetime: item?.meta?.launch_datetime,
          element_type: "countdown",
          id: item.id,
          display_duration: item?.display_duration || 5,
          text: slide?.text || "Chupa Chups",
          text_color: slide?.meta?.text_color,
          background_color: slide?.background_color,
        }))

        slidesWihtoutMeta.push(...slide)
      })

      const activeSlides = slidesWihtoutMeta.filter((s) => {
        const now = new Date().toISOString()
        if (
          (s.launch_datetime > now && s.element_type === "countdown") ||
          s.element_type !== "countdown"
        ) {
          return s
        }
      })

      if (
        JSON.stringify(activeSlides) !== localStorage.getItem("activeSlides")
      ) {
        setSlides(activeSlides)
        localStorage.setItem("activeSlides", JSON.stringify(activeSlides))
      }
    })
  }
  const SocketConnect = () => {
    const Connection = new WebSocket(
      Configs?.SOCKET?.url ||
        "wss://dd11qfkzg6.execute-api.me-south-1.amazonaws.com/staging?group=videoscreen"
    )
    Connection.onopen = () => {
      console.log("Socket Connected. ✅")
    }
    Connection.onclose = () => {
      console.log("Socket Disconnected. ❌")
      setTimeout(function () {
        SocketConnect()
      }, 1000)
    }
    Connection.onerror = function (err) {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket 🛑"
      )
      setTimeout(function () {
        SocketConnect()
      }, 1000)
    }
    Connection.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data.message === "Updated elements") {
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    // SocketConnect()
    localStorage.setItem("activeSlides", "")

    getData()
    setInterval(() => getData(), 100000)

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [props])

  const handleResize = () => {
    if (window.innerHeight == window.screen.height) {
      setIsFullScreen(true)
    } else {
      setIsFullScreen(false)
    }
  }

  useEffect(() => {
    if (slides.length) {
      setTimeout(
        NextSlide.bind(this, current),
        [slides[current]]["display_duration"] * 1000
      )
    }
    // eslint-disable-next-line
  }, [slides])

  return loading || slides.length === 0 ? (
    <div className="loading-container">
      <div className="loading">
        <span>Loading...</span>
      </div>
    </div>
  ) : (
    <ul className="slider">
      {slides.map((slide, key) => {
        const Component = Schema.SLIDES_TYPE[slide["element_type"]]["component"]
        return Schema.SLIDES_TYPE[slide["element_type"]] ? (
          <li
            key={key}
            className={`${
              key === current ? "slide-item active" : "slide-item"
            } ${Schema.SLIDES_TYPE[slide["element_type"]]["className"]}`}
          >
            <Component
              isFullScreen={isFullScreen}
              {...slide}
              active={key === current}
            />
          </li>
        ) : null
      })}
    </ul>
  )
}

export default Home
