import Countdown from 'react-countdown';
import {dateToEpoch} from "../../helpers/date";

const Completionist = () => <span>End Event!</span>;

const renderer = ({ days,hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return <span>{days}:{hours}:{minutes}:{seconds}</span>;
    }
};

const CountdownThemeThree = (props) => {
    // console.log('COUNTDOWN: ', props);
    return (
        <div className='countdown countdown-theme-three'>
            <Countdown
                date={dateToEpoch(props.launch_datetime) * 1000}
                renderer={renderer}
            />
        </div>
    )
}

export default CountdownThemeThree;
