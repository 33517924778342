import Video from "../base/video";
import Countdown from "../base/countdown";
import ThemeOne from "../base/countdown_themes/theme1";
import ThemeTwo from "../base/countdown_themes/theme2";
import ThemeThree from "../base/countdown_themes/theme3";
import ThemeFour from "../base/countdown_themes/theme4";

export const Schema = {
    SLIDES_TYPE: {
        countdown: {
            className: 'ct',
            component: Countdown,
            time: 20
        },
        logo: {
            className: 'vid',
            component: Video,
            time: 10
        },
        theme: {
            className: 'vid',
            component: Video,
            time: 10
        }
    },
    COUNTDOWN_THEME: {
        theme1: ThemeOne,
        theme2: ThemeTwo,
        theme3: ThemeThree,
        theme4: ThemeFour,
    },
    EFFECTS: ['rotate', 'scale', 'skew']
};
