import Axios from 'axios';
import {Configs} from "../configs/configs";

const Query = ({uri, ...other}) => new Promise((resolve, reject) => {
    const URL = Configs.BASE_URL + uri;
    const PAYLOAD = {
        url: URL,
        ...other
    }
    Axios(PAYLOAD)
        .then(response => {
            resolve({
                status: response.status,
                data: response.data
            });
        })
        .catch(error => {
            if(error && error.response && error.response.status){
                reject({
                   status: error.response.status,
                   data: error.response.data
                });
            }else{
                reject({
                    status: 0,
                    data: {
                        message: 'Unknown Error'
                    }
                });
            }
        })
});

export default Query;
