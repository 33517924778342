import moment from "moment"
import { useEffect, useState } from "react"

import { Configs } from "../configs/configs"

const CountdownSlide = (props) => {
  // General info
  const {
    background,
    logo,
    text,
    background_color,
    formatted,
    text_color,
    isFullScreen,
  } = props

  // Countdown info
  const { hours, minutes, days } = formatted

  const style = {
    backgroundColor: background_color,
    ...(background && {
      backgroundImage: `url('${encodeURI(background)}')`,
    }),
    ...(logo && {
      backgroundImage: `url('${encodeURI(logo)}')`,
    }),
    ...(isFullScreen && { width: "100%" }),
  }

  return (
    <section className="countdown">
      <div className="countdown-slide" style={style}>
        <header className="timers">
          <div className="timer">
            {days || "00"}
            <span style={{ color: text_color || "#ffe44b" }}>Days</span>
          </div>

          <div className="timer">
            {hours || "00"}
            <span style={{ color: text_color || "#ffe44b" }}>Hours</span>
          </div>
          <div className="timer">
            {minutes || "00"}
            <span style={{ color: text_color || "#ffe44b" }}>Minutes</span>
          </div>
        </header>
        <p style={{ color: text_color || "#ffe44b" }} className="text">
          {text}
        </p>
        <figure className="logo">
          {/* {logo && <img src={logo} alt="logo" />} */}
          {background && (
            <img
              className="background-image"
              src={background}
              alt="background"
            />
          )}
           {logo && (
            <img
              className="background-image"
              src={logo}
              alt="background"
            />
          )}
        </figure>
      </div>
    </section>
  )
}

export default CountdownSlide
