import ReactCountdown from "react-countdown"
import CountdownSlide from "./ContdownRenderer"

const Countdown = (props) => {
  return (
    <ReactCountdown
      renderer={(times) => <CountdownSlide {...times} {...props} />}
      date={props.launch_datetime}
    />
  )
}
export default Countdown
