import { Configs } from "../configs/configs"
import Poster from "../assets/images/poster.png"
import { useEffect, useRef } from "react"
const Video = ({ file_url, active, isFullScreen }) => {
  const videoRef = useRef()
  useEffect(() => {
    if (active) {
      setTimeout(() => {
        videoRef.current.play()
      }, 500)
    } else {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
    }
  }, [active])

  const onError = (e) => {
    e.target.src = "./default.mp4"
    if (active) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
    }
  }

  // https://developer.chrome.com/blog/autoplay/
  return (
    <video
      preload="none"
      onError={onError}
      muted
      loop
      ref={videoRef}
      poster={Poster}
      src={file_url}
    />
  )
}
export default Video
