const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === "development"

const DEV_API = "http://localhost:8000/"
const PRO_API = "http://localhost:8000/"
const CUS_API = "http://localhost:8000/"

const DEV_SOCKET_API = process.env.REACT_APP_DEV_SOCKET_API
const PRO_SOCKET_API = process.env.REACT_APP_PRO_SOCKET_API

const BASE_URL_MAP = {
  "d20io4dkar6hx.cloudfront.net": PRO_API,
  "localhost:3000": DEV_API,
  "chupa-video-screen.calistu.site": DEV_API,
  "video-screen-front.chupachups.ae": CUS_API,
}

const baseUrl ="https://chupvideoapp.chupachups.ae/"
console.log(baseUrl);
export const Configs = {
  BASE_URL: baseUrl,
  SOCKET: {
    timeout: 3,
    userName: "calistu",
    password: "THOUScroThEnYlEgYmASELostrOSwA",
    keepAliveInterval: 30,
    // url                 : 'rabbit.calistu.com',
    url: IS_DEV ? DEV_SOCKET_API : PRO_SOCKET_API,
    // port                : 15675,
    port: 8060,
    qos: 1,
    show_console: true,
    channel: "monitor_updated_elements_queue",
  },
}
